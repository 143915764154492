var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"window",staticStyle:{"margin-left":"8.5%","margin-right":"11%","width":"85%"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.userData,"sort-by":"calories"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Pengumuman")]),_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"600px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"color":"success","dark":""},on:{"click":_vm.reset}},'v-btn',attrs,false),on),[_vm._v(" Tambah Pengumuman ")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-toolbar',{attrs:{"color":"primary","dark":""}},[_vm._v(_vm._s(_vm.formTitle))]),_c('v-card-text',[_c('v-container',[(_vm.validation.role_name)?_c('v-alert',{attrs:{"dense":"","dismissible":"","text":"","type":"warning"}},[_vm._v(" "+_vm._s(_vm.validation.role_name[0])+" ")]):_vm._e(),(_vm.validation.role_desc)?_c('v-alert',{attrs:{"dense":"","dismissible":"","text":"","type":"warning"}},[_vm._v(" "+_vm._s(_vm.validation.role_desc[0])+" ")]):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Judul Pengumuman"},model:{value:(_vm.formData.judul_pengumuman),callback:function ($$v) {_vm.$set(_vm.formData, "judul_pengumuman", $$v)},expression:"formData.judul_pengumuman"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('vue-editor',{attrs:{"editorToolbar":_vm.customToolbar},model:{value:(_vm.formData.isi_pengumuman),callback:function ($$v) {_vm.$set(_vm.formData, "isi_pengumuman", $$v)},expression:"formData.isi_pengumuman"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Start Date","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.formData.start_date),callback:function ($$v) {_vm.$set(_vm.formData, "start_date", $$v)},expression:"formData.start_date"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c('v-date-picker',{on:{"input":function($event){_vm.menu2 = false}},model:{value:(_vm.formData.start_date),callback:function ($$v) {_vm.$set(_vm.formData, "start_date", $$v)},expression:"formData.start_date"}})],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"End Date","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.formData.end_date),callback:function ($$v) {_vm.$set(_vm.formData, "end_date", $$v)},expression:"formData.end_date"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu3),callback:function ($$v) {_vm.menu3=$$v},expression:"menu3"}},[_c('v-date-picker',{on:{"input":function($event){_vm.menu3 = false}},model:{value:(_vm.formData.end_date),callback:function ($$v) {_vm.$set(_vm.formData, "end_date", $$v)},expression:"formData.end_date"}})],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-file-input',{attrs:{"rules":_vm.rules,"accept":"application/pdf","placeholder":"Upload File Pengumuman","label":"Upload File Pengumuman"},model:{value:(_vm.formData.file_pengumuman),callback:function ($$v) {_vm.$set(_vm.formData, "file_pengumuman", $$v)},expression:"formData.file_pengumuman"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.close}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.save}},[_vm._v(" Save ")])],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v("Apakah anda ingin menghapus data ini?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeDelete}},[_vm._v("Batal")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.deleteItemConfirm}},[_vm._v("Hapus")]),_c('v-spacer')],1)],1)],1)],1),_c('v-divider')]},proxy:true},{key:"item.ISI_PENGUMUMAN",fn:function(ref){
var item = ref.item;
return [_c('div',{domProps:{"innerHTML":_vm._s(item.ISI_PENGUMUMAN)}})]}},{key:"item.START_DATE",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.convertDate(item.START_DATE))+" ")]}},{key:"item.END_DATE",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.convertDate(item.END_DATE))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")]),(item.FILENAME === null)?_c('v-icon',{attrs:{"small":"","disabled":""},on:{"click":function($event){return _vm.deleteFile(item)}}},[_vm._v(" mdi-image-broken ")]):_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteFile(item)}}},[_vm._v(" mdi-image-broken ")])]}}],null,true)},[_vm._v("convertDate ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }