<template>
  <v-container class="window" style="margin-left:8.5%; margin-right:11%; width: 85%;">
    <v-data-table :headers="headers" :items="userData" sort-by="calories" class="elevation-1">
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Pengumuman</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="600px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="success" dark class="mb-2" v-bind="attrs" v-on="on" @click="reset">
                Tambah Pengumuman
              </v-btn>
            </template>
            <v-card>
              <v-toolbar color="primary" dark>{{ formTitle }}</v-toolbar>
              <v-card-text>
                <v-container>
                  <v-alert dense dismissible text type="warning" v-if="validation.role_name">
                    {{ validation.role_name[0] }}
                  </v-alert>
                  <v-alert dense dismissible text type="warning" v-if="validation.role_desc">
                    {{ validation.role_desc[0] }}
                  </v-alert>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field v-model="formData.judul_pengumuman" label="Judul Pengumuman"></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <vue-editor v-model="formData.isi_pengumuman" :editorToolbar="customToolbar"></vue-editor>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="6" md="6">
                      <v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="40"
                        transition="scale-transition" offset-y min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field v-model="formData.start_date" label="Start Date" prepend-icon="mdi-calendar"
                            readonly v-bind="attrs" v-on="on"></v-text-field>
                        </template>
                        <v-date-picker v-model="formData.start_date" @input="menu2 = false"></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-menu v-model="menu3" :close-on-content-click="false" :nudge-right="40"
                        transition="scale-transition" offset-y min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field v-model="formData.end_date" label="End Date" prepend-icon="mdi-calendar" readonly
                            v-bind="attrs" v-on="on"></v-text-field>
                        </template>
                        <v-date-picker v-model="formData.end_date" @input="menu3 = false"></v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="6" md="6">
                      <v-file-input :rules="rules" accept="application/pdf" placeholder="Upload File Pengumuman"
                        label="Upload File Pengumuman" v-model="formData.file_pengumuman"></v-file-input>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close">
                  Cancel
                </v-btn>
                <v-btn color="blue darken-1" text @click="save">
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5">Apakah anda ingin menghapus data ini?</v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete">Batal</v-btn>
                <v-btn color="blue darken-1" text @click="deleteItemConfirm">Hapus</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
        <v-divider></v-divider>
      </template>
      <template v-slot:[`item.ISI_PENGUMUMAN`]="{ item }">
        <div v-html="item.ISI_PENGUMUMAN"></div>
      </template>convertDate
      <template v-slot:[`item.START_DATE`]="{ item }">
        {{ convertDate(item.START_DATE) }}
      </template>
      <template v-slot:[`item.END_DATE`]="{ item }">
        {{ convertDate(item.END_DATE) }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small @click="editItem(item)">
          mdi-pencil
        </v-icon>
        <v-icon small @click="deleteItem(item)">
          mdi-delete
        </v-icon>
        <v-icon small @click="deleteFile(item)" v-if="item.FILENAME === null" disabled>
          mdi-image-broken
        </v-icon>
        <v-icon small @click="deleteFile(item)" v-else>
          mdi-image-broken
        </v-icon>
      </template>
      <!-- <template v-slot:no-data>
      <v-btn
        color="primary"
        @click="initialize"
      >
        Reset
      </v-btn>
    </template> -->
    </v-data-table>
  </v-container>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex'
import { VueEditor } from 'vue2-quill-editor'
export default {
  data: () => ({
    dialog: false,
    dialogDelete: false,
    headers: [
      { text: 'Judul Pengumuman', value: 'JUDUL_PENGUMUMAN', width: '15%' },
      { text: 'Isi Pengumuman', value: 'ISI_PENGUMUMAN', width: '15%' },
      { text: 'Tanggal Mulai', value: 'START_DATE' },
      { text: 'Tanggal Selesai', value: 'END_DATE' },
      { text: 'File Download', value: 'REALNAME' },
      { text: 'Action', value: 'actions', sortable: false, align: 'center' }
    ],
    editedIndex: -1,
    userData: [],
    userUpdate: {},
    validation: [],
    customToolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      ['code-block', 'blockquote'],
      [{ 'indent': '-1' }, { 'indent': '+1' }],
      [{ 'header': [1, 2, 3, 4, 5, 6, !1] }],
      [{ 'color': [] }, { 'background': [] }],
      [{ 'font': [] }][{ 'align': [] }]
    ],
    formData: [
    ],
    menu2: false,
    menu3: false,
    rules: [
      value => !value || value.size < 10000000 || 'Avatar size should be less than 10 MB!',
    ]
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'Tambah Pengumuman' : 'Edit Pengumuman'
    },
    ...mapGetters({
      user: 'user',
    })
  },
  components: {
    VueEditor
  },
  watch: {
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
  },

  created() {
    let uri = `getDataAnnouncementInquiry`;
    axios.post(uri).then(response => {
      this.userData = response.data.data;
    });
  },

  methods: {
    editItem(item) {
      var todayDate = new Date().toISOString().slice(0, 10);
      this.editedIndex = this.userData.indexOf(item)
      this.formData.judul_pengumuman = item.JUDUL_PENGUMUMAN;
      this.formData.isi_pengumuman = item.ISI_PENGUMUMAN;
      if (item.START_DATE === '0000-00-00' || item.START_DATE == null) {
        this.formData.start_date = todayDate;
      } else {
        this.formData.start_date = item.START_DATE;
      }
      if (item.END_DATE === '0000-00-00' || item.END_DATE == null) {
        this.formData.end_date = todayDate;
      } else {
        this.formData.end_date = item.END_DATE;
      }
      // this.formData.end_date = item.END_DATE;
      this.formData.id_pengumuman = item.ID_PENGUMUMAN;
      // this.formData.file_pengumuman = item.FILE_PENGUMUMAN;
      // this.formData = Object.assign({}, item)
      // formData.append('judul_pengumuman', this.formData.judul_pengumuman)
      // formData.append('isi_pengumuman', this.formData.isi_pengumuman)
      // formData.append('start_date', this.formData.start_date)
      // formData.append('end_date', this.formData.end_date)
      // formData.append('file_pengumuman', this.formData.file_pengumuman)
      // console.log(this.formData);
      this.dialog = true
    },
    deleteFile(item) {
      if (item.FILENAME !== null) {
        this.formData.id_pengumuman = item.ID_PENGUMUMAN;
        const formData = new FormData();
        formData.append('id_pengumuman', this.formData.id_pengumuman);

        axios({
          method: 'post',
          url: 'deleteFilePengumuman',
          data: formData
        })
          .then(() => {
            let uri = `getDataAnnouncementInquiry`;
            axios.post(uri).then(response => {
              this.userData = response.data.data;
            });
            this.$swal.fire(
              'Sukses!',
              'File Berhasil dihapus',
              'success'
            )
            this.closeDelete()
          })
          .catch(error => {
            console.log(error.response)
            this.$swal.fire(
              'Gagal!',
              'FFile Gagal dihapus',
              'warning'
            )
          })
      }
    },
    deleteItem(item) {
      // this.editedIndex = this.userData.indexOf(item)
      // this.formData = Object.assign({}, item)
      // this.dialogDelete = true
      this.formData.id_pengumuman = item.ID_PENGUMUMAN;
      const formData = new FormData()
      formData.append('id_pengumuman', this.formData.id_pengumuman)
      this.$swal.fire({
        title: 'Apakah anda ingin menghapus data ini?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Hapus'
      }).then((result) => {
        if (result.isConfirmed) {
          axios({
            method: 'post',
            url: 'deletePengumuman',
            data: formData
          })
            .then(() => {
              let uri = `getDataAnnouncementInquiry`;
              axios.post(uri).then(response => {
                this.userData = response.data.data;
              });
              this.$swal.fire(
                'Sukses!',
                'Data Berhasil dihapus',
                'success'
              )
              this.closeDelete()
            })
            .catch(error => {
              console.log(error.response)
              this.$swal.fire(
                'Gagal!',
                'Data Gagal dihapus',
                'warning'
              )
            })
        }
      })
    },
    deleteItemConfirm() {
      axios.delete(`v1/deleterole/${this.formData.id}`)
        .then(() => {
          let uri = `v1/roles`;
          axios.get(uri).then(response => {
            this.userData = response.data.data;
          });
        }).catch((error) => {
          alert(error);
        });
      this.closeDelete()
    },

    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.formData = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.formData = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    reset() {
      this.validation = [];
      this.validation.splice(0);
      this.formData.id_pengumuman = '';
      this.formData.judul_pengumuman = '';
      this.formData.isi_pengumuman = '';
      this.formData.start_date = '';
      this.formData.end_date = '';
    },
    convertDate(date) {
      if (date === '0000-00-00' || date == null) {
        var todayDate = new Date().toISOString().slice(0, 10);
        return todayDate;
      } else {
        return date;
      }
    },
    save() {
      // console.log(this.formData.isi_pengumuman);
      if (typeof (this.formData.judul_pengumuman) !== 'undefined' && this.formData.judul_pengumuman !== '' && typeof (this.formData.isi_pengumuman) !== 'undefined' && this.formData.isi_pengumuman !== '<p><br></p>') {
        if (this.editedIndex > -1) {
          if((typeof (this.formData.start_date) !== 'undefined' && this.formData.start_date !== '' && this.formData.end_date !== 'undefined' && this.formData.end_date !== '')  && (this.formData.start_date === this.formData.end_date)){
            this.$swal.fire(
              'Peringatan!',
              'Start Date dan End Date tidak boleh sama!',
              'warning'
            )
          }else{
            if(this.formData.start_date>this.formData.end_date){
              this.$swal.fire(
                'Peringatan!',
                'Start Date tidak boleh lebih besar dari End Date!',
                'warning'
              )
            }else{
              const formData = new FormData()
              if (this.formData.file_pengumuman) {
                formData.append('id_pengumuman', this.formData.id_pengumuman)
                formData.append('judul_pengumuman', this.formData.judul_pengumuman)
                formData.append('isi_pengumuman', this.formData.isi_pengumuman)
                formData.append('start_date', this.formData.start_date)
                formData.append('end_date', this.formData.end_date)
                formData.append('file_pengumuman', this.formData.file_pengumuman)
              } else {
                formData.append('id_pengumuman', this.formData.id_pengumuman)
                formData.append('judul_pengumuman', this.formData.judul_pengumuman)
                formData.append('isi_pengumuman', this.formData.isi_pengumuman)
                formData.append('start_date', this.formData.start_date)
                formData.append('end_date', this.formData.end_date)
                // formData.append('file_pengumuman', this.formData.file_pengumuman)
              }
              this.$swal.fire({
                title: 'Apakah anda ingin mengupdate data ini?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Update'
              }).then((result) => {
                if (result.isConfirmed) {
                  axios({
                    method: 'post',
                    url: 'updateAnnouncement',
                    data: formData
                  })
                    .then(() => {
                      let uri = `getDataAnnouncementInquiry`;
                      axios.post(uri).then(response => {
                        this.userData = response.data.data;
                      });
                      this.$swal.fire(
                        'Sukses!',
                        'Data berhasil di update!',
                        'success'
                      )
                      this.close();
                    })
                    .catch(error => {
                      this.validation = error.response.data.data;
                      this.$swal.fire(
                        'Gagal!',
                        'Data Gagal diupdate',
                        'warning'
                      )
                    })
                }
              })
            }
          }
        } else {
          if((typeof (this.formData.start_date) !== 'undefined' && this.formData.start_date !== '' && this.formData.end_date !== 'undefined' && this.formData.end_date !== '') && (this.formData.start_date === this.formData.end_date)){
            this.$swal.fire(
              'Peringatan!',
              'Start Date dan End Date tidak boleh sama!',
              'warning'
            )
          }else{
            if(this.formData.start_date>this.formData.end_date){
              this.$swal.fire(
                'Peringatan!',
                'Start Date tidak boleh lebih besar dari End Date!',
                'warning'
              )
            }else{
              const formData = new FormData()
  
              formData.append('judul_pengumuman', this.formData.judul_pengumuman)
              formData.append('isi_pengumuman', this.formData.isi_pengumuman)
              formData.append('start_date', this.formData.start_date)
              formData.append('end_date', this.formData.end_date)
              formData.append('file_pengumuman', this.formData.file_pengumuman)
              this.$swal.fire({
                title: 'Apakah anda ingin menambahkan data ini?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Tambah'
              }).then((result) => {
                if (result.isConfirmed) {
                  axios({
                    method: 'post',
                    url: 'createAnnouncement',
                    data: formData
                  })
                    .then(() => {
                      let uri = `getDataAnnouncementInquiry`;
                      axios.post(uri).then(response => {
                        this.userData = response.data.data;
                      });
                      this.$swal.fire(
                        'Sukses!',
                        'Data berhasil di simpan!',
                        'success'
                      )
                      this.close();
                    })
                    .catch(error => {
                      this.validation = error.response.data.data;
                      this.$swal.fire(
                        'Gagal!',
                        'Data Gagal disimpan',
                        'warning'
                      )
                    })
                }
              })
            }
          }
        }
      } else {
        this.$swal.fire(
          'Gagal!',
          'Input Judul dan Isi Pengumuman Tidak Boleh Kosong!',
          'warning'
        )
      }
    },
  },

}
</script>